'use client'

import { useEffect } from 'react'
import { useRouter } from 'next/navigation'

import { AuthContainer, UserProvider, useUser } from '@/components'

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <UserProvider>
      <InnerLayout>
        <AuthContainer>{children}</AuthContainer>
      </InnerLayout>
    </UserProvider>
  )
}

const InnerLayout = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (!loading && user) {
      router.push('/')
    }
  }, [loading, user, router])

  return children
}

export default Layout
